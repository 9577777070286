import { Box } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
interface AuthLayoutProps {
  children: ReactNode | ReactNode[];
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Box mx="5">
      <Box
        display="flex"
        flexDir="column"
        mx="auto"
        maxW="md"
        p="10"
        my="11vh"
        bgColor="white"
        color="black"
        borderColor="#CCCCD4"
        borderWidth="2px"
        rounded="35px"
      >
        {children}
      </Box>
    </Box>
  );
};

export default AuthLayout;
