const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
// const checkIfValidPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/
const containsArabicCharacters = /[\u0600-\u06FF]/;
const passwordRegex = /^[^\s]{7,31}$/

export function ValidateEmail(email : string) : boolean {
  return (emailRegex.test(email) && 
          !containsArabicCharacters.test(email))
}

export function ValidatePassword(password : string) {
  return (passwordRegex.test(password) &&
          !containsArabicCharacters.test(password))
}
