import { Button, FormControl, FormLabel, Input, Text, InputGroup, InputRightElement } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import AuthLayout from "../../layouts/AuthLayout";
import { ValidatePassword } from "../../common/auth";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const token = searchParams.get("tkn");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickPassword = () => setShowPassword(!showPassword);
  const handleClickConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);  

  const submit = async () => {
    if (!password || !token)
      throw new Error(
        "There has been a critical error. Please contact support@shakoomaku.com"
      );
    if (password !== confirmPassword) throw new Error("Passwords don't match");

    if (!ValidatePassword(password))
      throw new Error(
        "Password is invalid, must be at least 6 characters and include at least one number and one uppercase letter."
      );

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}accounts/reset-password`,
      { password, token },
      {
        headers: {
          "application-authorization-token":
            process.env.REACT_APP_BACKEND_AUTH_TOKEN,
        },
      }
    );
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(submit, {
    onError: (e: Error | AxiosError) => {
      return alert(e);
    },
  });

  useEffect(() => {
    if (!token) navigate("/");
  }, []);

  if (isLoading) return <LoadingAnimation />;

  if (isSuccess && !isError)
    return (
      <AuthLayout>
        <Text fontSize="2xl" textAlign="center">
          Your password has been reset!
        </Text>
        <Button
          variant="accent"
          mx="auto"
          w="60"
          my="10"
          onClick={() => navigate("/login")}
        >
          Login
        </Button>
      </AuthLayout>
    );

  return (
    <AuthLayout>
    <Text fontSize="3xl">Reset Password</Text>
    <FormControl my="5">
      <FormLabel>New Password</FormLabel>
      <InputGroup size="md" rounded="2xl">
        <Input
          pr="4.5rem"
          type={showPassword ? "text" : "password"}
          placeholder="Enter password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClickPassword}>
            {showPassword ? "Hide" : "Show"}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormLabel mt="5">Confirm New Password</FormLabel>
      <InputGroup size="md" rounded="2xl">
        <Input
          pr="4.5rem"
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm password"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClickConfirmPassword}>
            {showConfirmPassword ? "Hide" : "Show"}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
    <Button
      variant="accent"
      mx="auto"
      w="60"
      mt="3"
      onClick={() => mutate()}
      isDisabled={!password}
    >
      Submit
    </Button>
  </AuthLayout>
  );
};

export default ResetPassword;
