import axios from "axios";
import { FC, useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import LoadingAnimation from "./components/LoadingAnimation";

interface ProtectedRouteInput {
  children: any;
}

const ProtectedRoute: FC<ProtectedRouteInput> = ({ children }) => {
  const isUserAuth = localStorage.getItem("token");
  const [searchParams] = useSearchParams();
  const [originalRoute, setOriRoute] = useState<string>("");
  const [processingToken, setProcessingToken] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getLoginToken = async () => {
    const token = searchParams.get("token");
    console.log("looking up token ", token);
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}accounts/login-from-quick-token`,
      {},
      {
        headers: {
          "application-authorization-token":
            process.env.REACT_APP_BACKEND_AUTH_TOKEN,
          token: token,
        },
      }
    );
    console.log(result);
    localStorage.setItem("token", result?.data.token);
    if (localStorage.getItem("token") !== null) {
      navigate(originalRoute);
    }
    setProcessingToken(false);
  };

  useEffect(() => {
    setOriRoute(location.pathname);
    if (searchParams.has("token")) {
      setProcessingToken(true);
      getLoginToken();
    }
  }, []);

  if (processingToken) {
    return <LoadingAnimation />;
  }

  if (!isUserAuth) {
    return (
      <Navigate
        to={
          searchParams.get("via")
            ? `/signup?via=${searchParams.get("via")}`
            : "/signup"
        }
        replace
      />
    );
  }

  return children;
};

export default ProtectedRoute;
