import { Box, Image, Link, VStack } from "@chakra-ui/react";
import { ReactComponent as AppleBadge } from "../../images/download_apple.svg";
import GoogleBadge from "../../images/google-play-badge.png";
import AppIcon from "../../images/favveicon.png";

interface DownloadSectionProps {
  appleAppStore: string;
  googlePlayStore: string;
}

const DownloadSection: React.FC<DownloadSectionProps> = ({ appleAppStore, googlePlayStore }) => (
  <Box display="flex" flexDirection={{base: "column", md: "row"}} justifyContent="space-between" marginX="auto" marginY={5} alignItems="center" width="auto" padding={5} bgColor="#ebebeb" rounded={20}>
    <VStack spacing="2" margin={30} paddingTop={5} alignItems={"center"}>
      <Link href={appleAppStore} isExternal>
        <AppleBadge width="auto" height={"60"}/> {/* Adjust this width to scale */}
      </Link>
      <Link href={googlePlayStore} isExternal>
        <Image src={GoogleBadge} alt="Google Play Store Logo" width="auto" height={"81px"} objectFit="contain" /> {/* Adjust this width to scale */}
      </Link>
    </VStack>
    <Box display={{base: "none", md: "block"}}>
      <Image src={AppIcon} alt="App Icon" width="auto" height="240px" objectFit="contain" /> 
    </Box>
  </Box>
);

export default DownloadSection;
