import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Grid, Progress, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import GreenStarryBackground from "../images/green_starry_bg.svg";
import RedStarryBackground from "../images/red_starry_bg.svg";
import YellowStarryBackground from "../images/yellow_starry_bg.svg";
import { ChildAccountProgress } from "../pages/Dashboard";
interface ProgressDropdownInput {
  onClose: () => void;
  selectedChildProgress: ChildAccountProgress | undefined;
  childName: string;
}

const ProgressDropdown: FC<ProgressDropdownInput> = ({
  selectedChildProgress,
  onClose,
  childName,
}) => {
  const [difficultySelection, setDifficultySelected] = useState<"easy" | "medium" | "hard" | "none">("none");

  if (!selectedChildProgress) return null;

  const toggleDifficulty = (diff: "easy" | "medium" | "hard" | "none") => {
    if (diff === difficultySelection) {
      setDifficultySelected("none")
    } else {
      setDifficultySelected(diff)
    }
  }

  const getLetterProgressForDifficulty = (letterObject : any) => {
    
    console.log("-------------------------");
    console.log(letterObject.progress)
    console.log(letterObject.easyProgress)
    console.log(letterObject.mediumProgress)
    console.log(letterObject.hardProgress)
    console.log(letterObject.easyMax)
    console.log(letterObject.mediumMax)
    console.log(letterObject.hardMax)
    console.log("-------------------------");

    const toReturn = ({
      "none"   : letterObject.progress,
      "easy"   : letterObject.easyProgress / letterObject.easyMax, 
      "medium" : letterObject.mediumProgress / letterObject.mediumMax, 
      "hard"   : letterObject.hardProgress / letterObject.hardMax, 
    } as any)[difficultySelection] as number

    console.log(`returning : ${toReturn}`);


    return toReturn
  }

  return (
    <Box
      display="flex"
      flexDir="column"
      borderWidth="1.5px"
      borderColor="#DBDBE1"
      borderRadius="3xl"
      maxW={["100%", "75vw"]}
      h="screen"
      mx="auto"
      backgroundColor="white"
      color="black"
    >
      <Box display="flex" mx="4" mt="5">
        <Text mx="8" mt="1" mb="6" fontSize="2xl">
          {childName}'s Progress
        </Text>

        <Button variant="accent" py="5" ml="auto" onClick={onClose}>
          <CloseIcon w="2" />
        </Button>
      </Box>

      <Box
        display="flex"
        flexDir={["column", "column", "column", "row"]}
        gap={["8", "8", "8", "0"]}
        px="10"
      >
        <Box display="flex" flexDirection="column" gap="6" fontSize="xl">
          <Box
            backgroundImage={GreenStarryBackground}
            backgroundSize="cover"
            backgroundColor="shakumaku.green"
            borderWidth="6px"
            borderColor={difficultySelection === "easy" ? "darkgrey" : "white"}
            cursor="pointer"
            fontSize="3xl"
            px="10"
            rounded="3xl"
            color="white"
            maxW="xs"
            minW={["xxs", "xs"]}
            textAlign="left"
            py="5"
            onClick={() => {toggleDifficulty("easy")}}
          >
            Easy
            <Progress
              my="5"
              borderWidth="1px"
              borderColor="white"
              h="4"
              value={selectedChildProgress.totalEasyProgress * 100}
              rounded="full"
              colorScheme="green"
            />
          </Box>
          <Box
            backgroundImage={YellowStarryBackground}
            backgroundSize="cover"
            backgroundColor="shakumaku.yellow"
            borderWidth="6px"
            borderColor={difficultySelection === "medium" ? "darkgrey" : "white"}
            fontSize="3xl"
            px="10"
            rounded="3xl"
            color="white"
            maxW="xs"
            textAlign="left"
            py="5"
            cursor={"pointer"}
            onClick={() => {toggleDifficulty("medium")}}
          >
            Medium
            <Progress
              my="5"
              borderWidth="1px"
              borderColor="white"
              h="4"
              value={selectedChildProgress.totalMediumProgress * 100}
              colorScheme="yellow"
              rounded="full"
            />
          </Box>
          <Box
            backgroundImage={RedStarryBackground}
            backgroundSize="cover"
            backgroundColor="shakumaku.red"
            borderWidth="6px"
            borderColor={difficultySelection === "hard" ? "darkgrey" : "white"}
            fontSize="3xl"
            px="10"
            rounded="3xl"
            color="white"
            maxW="xs"
            textAlign="left"
            py="5"
            cursor="pointer"
            onClick={() => {toggleDifficulty("hard")}}
          >
            Hard
            <Progress
              my="5"
              borderWidth="1px"
              borderColor="white"
              h="4"
              value={selectedChildProgress.totalHardProgress * 100}
              rounded="full"
              colorScheme="red"
            />
          </Box>
        </Box>
        <Box textAlign="center" px={["0", "10"]} w="full" mb="5">
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap="5"
            borderBottomWidth="2px"
            pb="2"
            fontSize={["xl", "2xl"]}
          >
            <Box>Letter</Box>
            <Box>Progress</Box>
            <Box>Completed</Box>
          </Grid>
          {selectedChildProgress.mainLetters.map((mainLetter, i: number) => {
            return (
              <Grid
                templateColumns="repeat(3, 1fr)"
                gap="5"
                mb="2"
                mt="4"
                alignItems="center"
              >
                <Box mt="2">{mainLetter.unicode}</Box>
                <Box mt="2">
                  <Progress
                    colorScheme={({
                      "none" : "blue", 
                      "easy" : "green", 
                      "medium" : "yellow",
                      "hard" : "red",
                    } as any)[difficultySelection] }
                    rounded="full"
                    value={getLetterProgressForDifficulty(mainLetter) * 100}
                    maxW="sm"
                    mx="auto"
                  />
                </Box>
                <Box mt="2">
                  {getLetterProgressForDifficulty(mainLetter) * 100 === 100 && <CheckCircleIcon />}
                </Box>
              </Grid>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressDropdown;
