import { Box, Text } from "@chakra-ui/react";
import FaqQuestion from "../../components/FaqQuestion";
import SettingsLayout from "../../layouts/SettingsLayout";

const questions = [
  {
    title: "How can I reset my password if I forget it?",
    content:
      "On the parent portal login page, simply click the 'Reset password' link and follow the instructions.",
  },
  {
    title:
      "How can I contact customer support if I have any issues or questions?",
    content:
      "If you have any questions or suggestions, feel free to reach out to us at support@shakoomaku.com",
  },
  {
    title: "How many children can I have using the app?",
    content:
      "You can have up to 2 children on the first tier of subscription. To add an extra account, please upgrade your subscription.",
  },
  {
    title: "How often is the app updated with new content and features?",
    content:
      "We will look to add a new version every 6-9 months, however, we will be updating the app and releasing content within the app a lot more frequently.",
  },
  {
    title:
      "Are there any privacy policies or guidelines in place to protect my child's personal information while using the app?",
    content:
      "We take the privacy of our users very seriously, especially when it comes to children. We have a strict privacy policy in place that outlines the measures we take to protect your child's personal information. Rest assured that we will not use any personal information for anything other than internal use, and we will not share it with any third parties without your explicit consent. Please refer to our privacy policy for more details",
  },
  {
    title:
      "I love what you guys are doing and want to help contribute to your mission of teaching my children Arabic! How can I do this?",
    content:
      "Thank you for your kind words and support for our mission to teach children Arabic. We appreciate the generosity and willingness to contribute to our cause. Instead of accepting direct donations, we have created a way for you to make a difference in the lives of families who are facing financial hardships or reside in regions where the subscription cost is not feasible. Our Pay It Forward (PIF) program allows you to donate to a fund that will sponsor free licenses for families who cannot afford our subscription. By participating in our PIF program, you have the opportunity to make a positive impact on the lives of children and families in need, and help spread the gift of language learning.",
  },
  {
    title: "What is the Pay It Forward (PIF) campaign?",
    content:
      "The Pay It Forward (PIF) campaign is an initiative where individuals can contribute to a fund that provides free access to the Shakoo Maku Arabic Learning App for families who are unable to afford the subscription fee or live in countries where the fee is not feasible to pay.",
  },
  {
    title: "Who can apply for the PIF campaign and how?",
    content:
      "If you are facing financial hardships or reside in regions where the subscription cost is not feasible, please send us an email at hello@shakoomaku.com and tell us where you live, a bit about your background to help us understand your situation, and the number of children you have who wish to use the app. This will be dependent on the number of sponsorships available.",
  },
];

const FAQ = () => {
  return (
    <SettingsLayout>
      <Box maxW="xl">
        <Text
          fontWeight="bold"
          fontSize="6xl"
          fontFamily="charlie-kingdom"
          textColor="shakumaku.red"
          textAlign="center"
        >
          FAQs
        </Text>

        {questions.map((question, i) => {
          return (
            <Box>
              <FaqQuestion title={question.title} content={question.content} />
            </Box>
          );
        })}
      </Box>
    </SettingsLayout>
  );
};

export default FAQ;
