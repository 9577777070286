import { Box } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import SignupForm from "../../components/SignupForm";

export interface SignupFieldInput {
  email: string;
  password: string;
  confirmPassword: string;
}

export interface AccountFieldInput {
  firstName: string;
  phoneNumber: string;
  parentOccupation: string;
  country: string;
  learntWhere: string;
  learntGoal: string;
}

export type SignupFieldKey = keyof SignupFieldInput;
export type AccountFieldKey = keyof AccountFieldInput;

const Signup = () => {
  const navigate = useNavigate();
  const [signupDetails, setSignupDetails] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [accountDetails, setAccountDetails] = useState({
    firstName: "",
    phoneNumber: "",
    parentOccupation: "",
    country: "",
    learntWhere: "",
    learntGoal: "",
  });

  const handleInputChange = (
    field: SignupFieldKey | AccountFieldKey,
    value: string
  ) => {
    if (
      field !== "email" &&
      field !== "password" &&
      field !== "confirmPassword"
    ) {
      setAccountDetails({ ...accountDetails, [field]: value });
    } else {
      setSignupDetails({ ...signupDetails, [field]: value });
    }
  };

  const signup = async () => {
    localStorage.removeItem("token");

    // Signup
    const signup = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}accounts/signup`,
      signupDetails,
      {
        headers: {
          "application-authorization-token":
            process.env.REACT_APP_BACKEND_AUTH_TOKEN,
        },
      }
    );
    console.log(signup);

    //Login
    const login = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}accounts/signin`,
      { email: signupDetails.email, password: signupDetails.password },
      {
        headers: {
          "application-authorization-token":
            process.env.REACT_APP_BACKEND_AUTH_TOKEN,
        },
      }
    );
    console.log(login);

    // Update
    const update = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}profile-data/update`,
      {
        token: login.data.token,
        documentName: "accountInformation",
        document: accountDetails,
      },
      {
        headers: {
          "application-authorization-token":
            process.env.REACT_APP_BACKEND_AUTH_TOKEN,
        },
      }
    );

    localStorage.setItem("token", login.data.token);
    navigate("/signup/subscription");
  };

  const { mutate, isLoading } = useMutation(signup, {
    onError: (e: AxiosError) => {
      if (e?.response?.status === 409) {
        return alert("Account with this email already Exists");
      } else {
        return alert(e);
      }
    },
  });

  if (isLoading) return <LoadingAnimation />;

  return (
    <Box display="flex" flexDir="column" alignContent="center">
      <SignupForm
        title="Sign Up"
        signupDetails={signupDetails}
        accountDetails={accountDetails}
        handleInputChange={handleInputChange}
        submit={mutate}
      />
    </Box>
  );
};

export default Signup;
