import { Box, Spinner } from "@chakra-ui/react";

const LoadingAnimation = () => {
  return (
    <Box display="flex" justifyContent="center" mt="40">
      <Spinner size="xl" thickness="4px" color="shakumaku.yellow" />
    </Box>
  );
};
export default LoadingAnimation;
