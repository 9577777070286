import { Box, Fade, FormControl } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AccountFieldInput,
  AccountFieldKey,
  SignupFieldInput,
  SignupFieldKey,
} from "../pages/Signup";
import SignupFormStep1 from "./SignupFormStep1";
import SignupFormStep2 from "./SignupFormStep2";

interface SignupFormInput {
  title: string;
  signupDetails: SignupFieldInput;
  accountDetails: AccountFieldInput;
  handleInputChange: (
    field: SignupFieldKey | AccountFieldKey,
    value: string
  ) => void;
  submit: () => void;
}

const SignupForm: FC<SignupFormInput> = ({
  title,
  signupDetails,
  accountDetails,
  handleInputChange,
  submit,
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const updateStep = (i: number) => {
    setStep(i);
  };

  return (
    <Box>
      <FormControl>
        {step === 0 && (
          <Fade in={step === 0}>
            <SignupFormStep1
              title={title}
              inputDetails={signupDetails}
              handleInputChange={handleInputChange}
              updateStep={updateStep}
            />
          </Fade>
        )}
        {step === 1 && (
          <Fade in={step === 1}>
            <SignupFormStep2
              inputDetails={accountDetails}
              handleInputChange={handleInputChange}
              updateStep={updateStep}
              submit={submit}
            />
          </Fade>
        )}
      </FormControl>
    </Box>
  );
};

export default SignupForm;
