import { ChakraProvider } from "@chakra-ui/react";
import Helmet from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import "./App.css";
import AuthVerify from "./common/AuthVerify";
import MobileNavbar from "./components/MobileNavbar";
import Navbar from "./components/Navbar";
import NotAvailableYet from "./components/NotAvailableYet";
import NotFound from "./components/NotFound";
import Dashboard from "./pages/Dashboard";
import AccountDeletion from "./pages/Dashboard/AccountDeletion/AccountDeletion";
import FAQ from "./pages/Dashboard/faqs";
import RequestResetPassword from "./pages/Dashboard/RequestResetPassword";
import ResetPassword from "./pages/Dashboard/ResetPassword";
import Settings from "./pages/Dashboard/Settings";
import UpdateBilling from "./pages/Dashboard/UpdateBilling";
import Download from "./pages/Download";
import Login from "./pages/Login";
import PreRegisterLogin from "./pages/Login/PreRegisterLogin";
import PreRegister from "./pages/PreRegister";
import PreStartSubscription from "./pages/PreRegister/PreStartSubscription";
import Signup from "./pages/Signup";
import StartSubscription from "./pages/Signup/StartSubscription";
import ProtectedRoute from "./ProtectedRoute";
import theme from "./theme";

const queryClient = new QueryClient();

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // This is to hide and control functionality available before and after launch
  const preLaunch = process.env.REACT_APP_PRELAUNCH;

  const signOut = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Helmet>
          <script>{`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}</script>
          <script
            async
            src="https://r.wdfl.co/rw.js"
            data-rewardful="0a59f7"
          ></script>
        </Helmet>
        <Navbar />
        <MobileNavbar />
        <Routes>
          {/* Before launch we replace signup flow with the pre-register flow
          this flow differs as it has them to pay now for their future subscription that starts only when the game launches.
          
          We also don't allow them to visit the dashboard as they could then theoretically break other stuff and theres not much value there for them
          
          If the user goes to pre-register OR login, but they're already logged in then we send them to complete the subscription page.
          If they have completed that subscription page, they get re-routed to the dashboard, which says nothings available for now.
          If they have not completed that subscription page, then they are prompted to complete it.
          */}
          {preLaunch === "true" && (
            <>
              <Route path="/" element={<Navigate to="/pre-register" />} />
              <Route
                path="/pre-register"
                element={
                  !localStorage.getItem("token") ? (
                    <PreRegister />
                  ) : (
                    <Navigate to="/pre-register/subscription" />
                  )
                }
              />
              <Route
                path="/pre-register/subscription"
                element={
                  <ProtectedRoute>
                    <PreStartSubscription />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/login"
                element={
                  !localStorage.getItem("token") ? (
                    <PreRegisterLogin />
                  ) : (
                    <Navigate to="/pre-register/subscription" />
                  )
                }
              />

              {/* Routes that are not available */}
              <Route path="/signup" element={<NotAvailableYet />} />
              <Route path="/dashboard" element={<NotAvailableYet />} />
              <Route path="/download" element={<NotAvailableYet />} />
              <Route path="/settings" element={<NotAvailableYet />} />
              <Route path="/update-billing" element={<NotAvailableYet />} />
              <Route path="/update-billing" element={<NotAvailableYet />} />
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {/* After launch we allow all of the other features */}
          {preLaunch === "false" && (
            <>
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/signup/subscription"
                element={
                  <ProtectedRoute>
                    <StartSubscription />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <Navigate
                    to={
                      searchParams.get("via")
                        ? `/dashboard?via=${searchParams.get("via")}`
                        : "/dashboard"
                    }
                    replace
                  />
                }
              />
              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/faqs"
                element={
                  <ProtectedRoute>
                    <FAQ />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account-deletion"
                element={
                  <ProtectedRoute>
                    <AccountDeletion />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/update-billing"
                element={
                  <ProtectedRoute>
                    <UpdateBilling />
                  </ProtectedRoute>
                }
              />
              <Route path="/download" element={<Download />} />
              <Route path="*" element={<NotFound />} />
            </>
          )}

          <Route
            path="/request-reset-password"
            element={<RequestResetPassword />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
        <AuthVerify signOut={signOut} />
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
