import { ArrowForwardIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Box, Button, FormControl, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Pen } from "../images/pen.svg";
import AuthLayout from "../layouts/AuthLayout";
import { SignupFieldInput, SignupFieldKey } from "../pages/Signup";
import { ValidateEmail, ValidatePassword } from "../common/auth";
interface SignupStep1FormInput {
  title: string;
  inputDetails: SignupFieldInput;
  handleInputChange: (field: SignupFieldKey, value: string) => void;
  updateStep: (i: number) => void;
}

const signupFields = [
  { label: "Email", value: "email" },
  { label: "Password", value: "password" },
  { label: "Confirm Password", value: "confirmPassword" },
];

const SignupFormStep1: FC<SignupStep1FormInput> = ({
  title,
  inputDetails,
  handleInputChange,
  updateStep,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState({password: false, confirmPassword: false});

  const handleClick = (key: 'password' | 'confirmPassword') => setShow({...show, [key]: !show[key]});

  const submit = (e: any) => {
    e.preventDefault();
    if (!ValidateEmail(inputDetails.email))
      return alert(
        "Your email is invalid."
      )
    if (!ValidatePassword(inputDetails.password))
      return alert(
        "Password is invalid, must be at least 6 characters and include at least one number, one uppercase letter, and cannot contain arabic letters."
      );
    if (inputDetails.password === inputDetails.confirmPassword) updateStep(1);
  };

  return (
    <AuthLayout>
      <Box position="relative" mx="auto" left="0">
        <Box position="absolute" top="-28">
          <Pen />
        </Box>
      </Box>
      <form onSubmit={submit}>
        <Text
          fontSize="3xl"
          fontWeight="bold"
          mx="auto"
          mb="35"
          mt="5"
          textAlign="center"
        >
          {title}
        </Text>
        <Box display="flex" flexDir="column">
          {signupFields.map((signupFieldInput, i) => {
            const signupField = signupFieldInput.value as SignupFieldKey;
            const isPasswordField = ['password', 'confirmPassword'].includes(signupField);
            return (
              <FormControl
                key={i}
                mb="3"
                isRequired
                isInvalid={
                  signupFieldInput.value === "confirmPassword" &&
                  inputDetails.password !== inputDetails.confirmPassword
                }
              >
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={isPasswordField ? (show[signupField as 'password' | 'confirmPassword'] ? "text" : "password") : "email"}
                    placeholder={signupFieldInput.label}
                    required
                    value={inputDetails[signupField]}
                    onChange={(e) => handleInputChange(signupField, e.target.value)}
                    rounded="full"
                    borderColor="shakumaku.grey"
                    borderWidth="1.5px"
                  />
                  {isPasswordField && (
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={() => handleClick(signupField as 'password' | 'confirmPassword')}>
                        {show[signupField as 'password' | 'confirmPassword'] ? <ViewOffIcon /> : <ViewIcon />}
                      </Button>
                    </InputRightElement>
                  )}
                </InputGroup>
              </FormControl>
            );
          })}
          <Button variant="secondary" mx="auto" w="40" mt="7" type="submit">
            Next <ArrowForwardIcon ml="2" />
          </Button>

          <Text
            mt="5"
            fontSize="sm"
            cursor="pointer"
            textAlign="center"
            color="lightgrey"
            onClick={() => navigate("/login")}
          >
            Already have an account?{" "}
            <Text as="span" color="shakumaku.blue" decoration="underline">
              Log In
            </Text>
          </Text>
        </Box>
      </form>
    </AuthLayout>
  );
};

export default SignupFormStep1;
