import { Box } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
interface MainLayoutProps {
  children: ReactNode | ReactNode[];
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <Box display="flex" flexDir="column" mx="auto" my="5">
      {children}
    </Box>
  );
};

export default MainLayout;
