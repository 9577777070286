import { Button, Flex, Text } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import AuthLayout from "../../layouts/AuthLayout";

const UpdateBilling = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const success = searchParams.get("success");
  const sessionID = searchParams.get("session_id");

  const submit = async () => {
    if (!sessionID || !success)
      throw new Error(
        "There has been a critical error. Please contact support@shakoomaku.com"
      );

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}payments/updatePaymentMethod`,
      { sessionID },
      {
        headers: {
          "application-authorization-token":
            process.env.REACT_APP_BACKEND_AUTH_TOKEN,
        },
      }
    );

    return res.data;
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(submit, {
    onError: (e: Error | AxiosError) => {
      return alert(
        "Payment details not updated, please contact support@shakoomaku.com"
      );
    },
  });

  useEffect(() => {
    mutate();
  }, []);

  if (isLoading) return <LoadingAnimation />;

  return (
    <AuthLayout>
      <Flex
        direction="column"
        fontFamily="Charlie-Kingdom"
        textColor="shakumaku.green"
      >
        {success && !isLoading && !isError ? (
          <>
            <Text fontSize="2xl" textAlign="center">
              Your payment method was successfully updated!
            </Text>
            <Button
              variant="accent"
              mx="auto"
              mt="5"
              onClick={() => navigate("/settings")}
              fontFamily="gilroy"
              fontWeight="800"
            >
              Continue
            </Button>
          </>
        ) : (
          <Text fontSize="2xl" textAlign="center">
            Payment details not updated, please contact support@shakoomaku.com
          </Text>
        )}
      </Flex>
    </AuthLayout>
  );
};

export default UpdateBilling;
