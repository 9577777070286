import { Box, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../images/logo.svg';

const Navbar = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('token');

  const signOut = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  return (
    <Box w="full" py="2" px={['0', '0', '5', '20']} display={['none', 'none', 'none', 'none', 'flex']}>
      <Box
        px={['0', '0', '0', '100']}
        cursor="pointer"
        onClick={() => {
          window.location.href = 'https://shakoomaku.com/';
        }}
        ml={['0', '2']}
        mx="auto"
      >
        <Logo height="100" width="155" />
      </Box>

      <Box display={['none', 'flex']} ml="auto" mt="5" pr={['0', '0', '0', '20']} gap="5">
        {isLoggedIn && process.env.REACT_APP_PRELAUNCH !== 'true' && (
          <>
            <Button variant="primary" my="1" onClick={() => navigate('/download')} px="8" fontSize="md">
              Download App
            </Button>
            <Button variant="pink" ml="auto" my="1" onClick={() => navigate('/dashboard')} px="8" fontSize="md">
              View Progress
            </Button>

            <Button variant="secondary" ml="auto" my="1" onClick={() => navigate('/faqs')} px="8" fontSize="md">
              FAQs
            </Button>
            <Button variant="primary" my="1" onClick={() => navigate('/settings')} px="8" fontSize="md">
              Settings
            </Button>
          </>
        )}
        {isLoggedIn && (
          <Box>
            <Button variant="accent" my="1" onClick={signOut} px="8" fontSize="md">
              Sign Out
            </Button>
          </Box>
        )}
        {!isLoggedIn && (
          <Flex gap="5" display={['none', 'flex']}>
            <Button
              variant="secondary"
              my="1"
              onClick={() => window.open('https://shakoomaku.getrewardful.com/signup/')}
              px="8"
              fontSize="md"
            >
              Join our referral program
            </Button>
            <Button
              variant="secondary"
              my="1"
              onClick={() => window.open('https://www.shakoomaku.com/')}
              px="8"
              fontSize="md"
            >
              Find out more
            </Button>
            <Button
              variant="secondary"
              my="1"
              onClick={() => window.open('https://www.youtube.com/watch?v=P4S519xPPX8&ab_channel=ShakooMakuTV')}
              px="8"
              fontSize="md"
            >
              Watch Trailer
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default Navbar;
