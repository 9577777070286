import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Box, Collapse, Flex, Link, Stack, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { ReactComponent as Logo } from '../images/logo.svg';

const authLinks: { title: string; href?: string }[] = [
  { title: 'Download App', href: 'download' },
  { title: 'View Progress', href: 'dashboard' },
  { title: 'FAQs', href: 'faqs' },
  { title: 'Settings', href: 'settings' },
  { title: 'Sign Out' }
];

const nonAuthLinks: { title: string; href?: string }[] = [
  { title: 'Join our referral program', href: 'https://shakoomaku.getrewardful.com/signup/' },
  { title: 'Find out more', href: 'https://www.shakoomaku.com/' },
  {
    title: 'Watch Trailer',
    href: 'https://www.youtube.com/watch?v=P4S519xPPX8&ab_channel=ShakooMakuTV'
  }
];

const NavLink: FC<{
  link: { title: string; href?: string };
  auth: boolean;
}> = ({ link, auth }) => {
  return (
    <Link
      px={2}
      rounded={'md'}
      _hover={{
        textDecoration: 'none'
      }}
      href={link?.href}
      target={auth ? '_parent' : '_blank'}
      fontSize="25px"
      mt="25vh"
      onClick={() => {
        if (link.title === 'Sign Out') {
          localStorage.clear();
          window.location.href = '/';
        }
      }}
    >
      {link.title}
    </Link>
  );
};

export default function MobileNavbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLoggedIn = localStorage.getItem('token');

  return (
    <Box pt="14" zIndex={30} display={['block', 'block', 'block', 'block', 'none']}>
      <Box
        bg={useColorModeValue(isOpen ? 'shakumaku.blue' : 'white', 'shakumaku.darkBlue')}
        px={4}
        position="fixed"
        top="0"
        w="full"
        zIndex={20}
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'} my="1">
          <Logo
            height="50"
            width="90"
            onClick={() => {
              window.location.href = 'https://shakoomaku.com/';
            }}
          />
          <Box mr="3" ml="auto" onClick={isOpen ? onClose : onOpen} cursor="pointer">
            {isOpen ? <CloseIcon color="white" boxSize={5} /> : <HamburgerIcon color="black" boxSize={7} />}
          </Box>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <Box pb={8} mt={5} height="100vh">
            <Stack as={'nav'} spacing={4}>
              {isLoggedIn
                ? authLinks.map((link, i) => <NavLink key={i} link={link} auth={true} />)
                : nonAuthLinks.map((link, i) => <NavLink key={i} link={link} auth={false} />)}
            </Stack>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}
