import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { FC } from "react";
import SignupLayout from "../layouts/SignupLayout";
import { AccountFieldInput, AccountFieldKey } from "../pages/Signup";

interface SignupStep2FormInput {
  inputDetails: AccountFieldInput;
  handleInputChange: (field: AccountFieldKey, value: string) => void;
  updateStep: (i: number) => void;
  submit: () => void;
}

countries.registerLocale(enLocale);
const countryObj = countries.getNames("en", { select: "official" });
const countryArr = Object.entries(countryObj).map(([key, value]) => {
  return {
    label: value,
    value: key,
  };
});

const accountFields = [
  {
    label: "First Name",
    value: "firstName",
    required: true,
    type: "input",
  },
  {
    label: "Last Name",
    value: "lastName",
    required: true,
    type: "input",
  },
  {
    label: "Phone Number",
    value: "phoneNumber",
    required: false,
    type: "input",
  },
  {
    label: "Parent Occupation",
    value: "parentOccupation",
    required: false,
    type: "input",
  },
  {
    label: "Country",
    value: "country",
    required: false,
    type: "select",
    selectOptions: countryArr,
  },
  {
    label: "Where did you hear about us?",
    value: "learntWhere",
    required: true,
    type: "select",
    selectOptions: [
      { label: "Youtube Ad", value: "youtubeAd" },
      { label: "Youtube Trailer", value: "youtubeTrailer" },
      { label: "Word of mouth", value: "wordOfMouth" },
      { label: "Google", value: "google" },
      { label: "Facebook", value: "facebook" },
      { label: "Instagram", value: "instagram" },
      { label: "Tiktok", value: "tiktok" },
      { label: "Other", value: "other" },
    ],
  },
  {
    label: "What is your goal?",
    value: "learntGoal",
    required: true,
    type: "select",
    selectOptions: [
      { label: "Learning Arabic as a native tongue", value: "nativeLanguage" },
      { label: "Learning Arabic as second language", value: "secondLanguage" },
      {
        label: "Exploring the Shakoo Maku world",
        value: "exploringShakooMaku",
      },
    ],
  },
];

const SignupFormStep2: FC<SignupStep2FormInput> = ({
  inputDetails,
  handleInputChange,
  updateStep,
  submit,
}) => {
  const submitForm = (e: any) => {
    e.preventDefault();
    submit();
  };

  return (
    <SignupLayout>
      <form onSubmit={submitForm}>
        <Text
          fontSize="3xl"
          fontWeight="bold"
          mx="auto"
          my="35"
          textAlign="center"
        >
          Account Setup
        </Text>
        <Box display="flex" flexDir="column">
          <Grid
            templateColumns="repeat(2, 1fr)"
            templateRows="repeat(2, 1fr)"
            rowGap="2"
            columnGap="4"
          >
            {accountFields.map((accountFieldInput, i) => {
              const accountField = accountFieldInput.value as AccountFieldKey;

              return (
                <FormControl mb="3" isRequired={accountFieldInput.required}>
                  {accountFieldInput.type === "input" ? (
                    <Input
                      p="6"
                      placeholder={accountFieldInput.label}
                      rounded="full"
                      borderColor="shakumaku.grey"
                      borderWidth="1.5px"
                      required={accountFieldInput.required}
                      value={inputDetails[accountField]}
                      onChange={(e) =>
                        handleInputChange(accountField, e.target.value)
                      }
                    />
                  ) : (
                    <Select
                      h="12"
                      rounded="full"
                      borderColor="shakumaku.grey"
                      borderWidth="1.5px"
                      value={inputDetails[accountField]}
                      required={accountFieldInput.required}
                      onChange={(e) => {
                        handleInputChange(accountField, e.target.value);
                      }}
                      placeholder={accountFieldInput.label}
                    >
                      {accountFieldInput.selectOptions?.map(
                        (selectOption, i) => {
                          return (
                            <option value={selectOption.value}>
                              {selectOption.label}
                            </option>
                          );
                        }
                      )}
                    </Select>
                  )}
                  <FormErrorMessage>Field is required.</FormErrorMessage>
                </FormControl>
              );
            })}
          </Grid>
          <Box display="flex" mx="auto" gap={["2", "5", "5", "5"]}>
            <Button variant="grey" w="40" mt="7" onClick={() => updateStep(0)}>
              <ArrowBackIcon mr="2" /> Back
            </Button>
            <Button variant="secondary" w="40" mt="7" type="submit">
              Submit <ArrowForwardIcon ml="2" />
            </Button>
          </Box>
        </Box>
      </form>
    </SignupLayout>
  );
};

export default SignupFormStep2;
