import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Collapse, useDisclosure } from "@chakra-ui/react";
import { FC } from "react";

interface FaqQuestionInput {
  title: string;
  content: string;
}

const FaqQuestion: FC<FaqQuestionInput> = ({ title, content }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      onClick={onToggle}
      fontSize="xl"
      my="5"
      cursor="pointer"
      color={isOpen ? "shakumaku.green" : "black"}
    >
      <Box display="flex">
        {title} <ChevronDownIcon ml="auto" my="auto" />
      </Box>
      <Box
        borderBottom="1px"
        borderColor={isOpen ? "shakumaku.green" : "black"}
        my="3"
      />
      <Collapse in={isOpen} animateOpacity>
        <Box color="black" mt="1" fontSize="md" ml="4">
          {content}
        </Box>
      </Collapse>
    </Box>
  );
};

export default FaqQuestion;
