import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import { FC } from "react";
import Lulu from "../images/lulu.png";
import Zayn from "../images/zayn.png";

interface ProfilePreviewInput {
  index: number;
  selectedChildIndex: number;
  isOpen: boolean;
  updateSelectedChild: (index: number) => void;
  profileData: {
    childName: string;
    gender: "M" | "F";
  };
}

const ProfilePreview: FC<ProfilePreviewInput> = ({
  index,
  selectedChildIndex,
  isOpen,
  updateSelectedChild,
  profileData,
}) => {
  const viewProgress = () => {
    updateSelectedChild(index);
  };

  return (
    <Box display="flex" flexDir="column" textAlign="center">
      <Box
        bg="lightgrey"
        h="250px"
        w="250px"
        borderRadius="100%"
        my="7"
        backgroundImage={profileData.gender === "F" ? Lulu : Zayn}
        backgroundSize="contain"
        borderWidth="4px"
      />
      <Text fontSize="xl" fontWeight="semibold" color="black">
        {profileData.childName}
      </Text>
      <Button
        variant="primary"
        my="3"
        onClick={viewProgress}
        pl="6"
        fontSize="md"
      >
        View Progress{" "}
        <Box pl="2" pr="1">
          <ChevronDownIcon />
        </Box>
        {/* {selectedChildIndex === index && isOpen && (
          <Box position="relative">
            <Box
              width="0"
              height="0"
              borderLeft="25px solid transparent"
              borderRight="25px solid transparent"
              borderBottom="25px solid white"
              position="absolute"
              top="24"
              right="10"
            />
          </Box>
        )} */}
      </Button>
    </Box>
  );
};

export default ProfilePreview;
