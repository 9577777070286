import { Box } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { ReactComponent as HappyLion } from "../images/happy_lion.svg";
import { ReactComponent as SadElephant } from "../images/sad_elephant.svg";

interface WarningMessageProps {
  children: ReactNode | ReactNode[];
  positive: boolean;
}

const WarningMessage: FC<WarningMessageProps> = ({ children, positive }) => {
  return (
    <Box position="relative" mt="64" fontFamily="Charlie-Kingdom" mx="5">
      {positive ? (
        <Box
          zIndex={-10}
          position="absolute"
          top="-270px"
          left="0"
          right="0"
          mx="auto"
          w="60"
        >
          <HappyLion />
        </Box>
      ) : (
        <Box
          zIndex={-10}
          position="absolute"
          top="-220px"
          left="0"
          right="0"
          mx="auto"
          w="96"
        >
          <SadElephant />
        </Box>
      )}

      <Box
        display="flex"
        flexDir="column"
        mx="auto"
        maxW="md"
        p="10"
        my="10vh"
        bgColor="white"
        color="black"
        borderColor="#CCCCD4"
        borderWidth="2px"
        rounded="35px"
        fontSize="30px"
        textColor="shakumaku.green"
        textAlign="center"
        zIndex={10}
      >
        {children}
      </Box>
    </Box>
  );
};

export default WarningMessage;
