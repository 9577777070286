export const overlayStyle : React.CSSProperties = {
    position: 'fixed',
    display: 'flex',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `rgba(${0.5*255},${0.5*255},${0.5*255},0.5)`,
    zIndex: 2,
    justifyContent: 'center',  // centers horizontally in the case of row direction
    alignItems: 'center' // centers vertically in the case of row direction
  };

export  const textStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    fontSize: '20px',
  };

export  const errorContainerStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    maxWidth: '400px',
  };

export  const errorButtonStyle: React.CSSProperties = {
    marginTop: '10px',
    padding: '8px 16px',
    borderRadius: '5px',
    backgroundColor: '#4caf50',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  };

export const buttonStyle : React.CSSProperties = 
  {
    fontSize:"2xl",
    padding:"8"
  }