import { Box, Button, Text, useBreakpointValue } from "@chakra-ui/react";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import axios from "axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import MainLayout from "../../layouts/MainLayout";
import { ReactComponent as MockUp } from "../../images/mockup_app_actual.svg";
import DownloadSection from "./DownloadSection";

const forceTestMobileDownloadButton = false

// TODO: when we launch this needs to be replaced with the links to the actual store front
const appleAppStore = process.env.REACT_APP_APPLE_APP_LINK ?? ""
const googlePlayStore = process.env.REACT_APP_GOOGLE_APP_LINK ?? ""

const buttonVariant = "primary"
const buttonPadding = "8"

const Download = () => {
  const isUserAuth = localStorage.getItem("token");
  const navigate = useNavigate();
  const svgDimensions = useBreakpointValue({ xl: "30%", md: "68%", base: "68%" });

  const isMobileActual = forceTestMobileDownloadButton || isMobile

  const handleDownload = () => {
    if (isIOS) window.open(appleAppStore, '_blank');
    else if (isAndroid) window.open(googlePlayStore, '_blank');
  }

  const getSubscriptionStatus = async () => {
    if (!isUserAuth) return {}
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}helper-subscription/status`,
      {},
      {
        headers: {
          userToken: localStorage.getItem("token"),
        },
      }
    );

    return res?.data;
  };

  const {
    data: subscriptionData,
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
  } = useQuery(["getSubscriptionStatus"], getSubscriptionStatus);

  if (isSubscriptionLoading) return <LoadingAnimation />;

  console.log(subscriptionData.status)

  const downloadText = (subscriptionData.status === "active") 
    ? "Thank you for purchasing Shakoo Maku. Next download the app to your device!" 
    : "Download the Shakoo Maku app to your device!";

  const DownloadButtonOrSection = isMobileActual
    ? <Button variant={"secondary"} fontSize="24" px={buttonPadding} mt="4" onClick={handleDownload}> Download </Button> 
    : <DownloadSection appleAppStore={appleAppStore} googlePlayStore={googlePlayStore} />

  const AfterBoxDownloadSection = isMobileActual && <DownloadSection appleAppStore={appleAppStore} googlePlayStore={googlePlayStore} />;

  return (
    <MainLayout>
      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        mb="20"
        justifyContent="center"
        gap={[20, 0]}
      >
        <MockUp width={svgDimensions} height={svgDimensions}/>
        <Box mx="5">
          <Box
            display="flex"
            flexDir="column"
            mx="auto"
            maxW="2xl"
            p="10"
            bgColor="white"
            color="black"
            borderColor="#CCCCD4"
            borderWidth="2px"
            rounded="35px"
          >
            <Text color="black" fontSize={24} align={"center"}>{downloadText}</Text>
            {DownloadButtonOrSection}
            <Button variant={buttonVariant} fontSize="24" mt="4" onClick={() => navigate("/dashboard")}>Continue to dashboard</Button>
          </Box>
        </Box>
        {AfterBoxDownloadSection}
      </Box>
    </MainLayout>
  )
};

export default Download;
