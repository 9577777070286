import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AuthVerify = ({ signOut }: any) => {
  let location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const decodedJwt: { expireDate: string } = jwt_decode(token);
    if (!decodedJwt) return;

    const expiryDate = new Date(decodedJwt.expireDate);
    const currentDate = new Date();
    // console.log(new Date());
    // console.log(expiryDate);
    if (expiryDate < currentDate) {
      console.log("Token expiry is not valid");
      signOut();
    } else {
      console.log("Token expiry is valid");
    }
  }, [location, signOut]);

  return null;
};

export default AuthVerify;
