import { defineStyle, defineStyleConfig, extendTheme } from "@chakra-ui/react";
import backgroundSvg from "./images/background.svg";

const primary = defineStyle({
  backgroundColor: "shakumaku.green",
  color: "white",
  rounded: "25px",
  py: "27px",
  outlineColor: "shakumaku.darkGreen",
  outlineWidth: "2px",
  outlineOffset: "-1px",
  fontWeight: 800,
  zIndex: 0,
});

const secondary = defineStyle({
  backgroundColor: "shakumaku.blue",
  color: "white",
  rounded: "25px",
  py: "27px",
  outlineColor: "shakumaku.darkBlue",
  outlineWidth: "2px",
  outlineOffset: "-1px",
  fontWeight: 800,
  zIndex: 0,
});

const pink = defineStyle({
  backgroundColor: "shakumaku.pink",
  color: "white",
  rounded: "25px",
  py: "27px",
  outlineColor: "shakumaku.darkPink",
  outlineWidth: "2px",
  outlineOffset: "-1px",
  fontWeight: 800,
  zIndex: 0,
});

const red = defineStyle({
  backgroundColor: "#E7533D",
  color: "white",
  rounded: "25px",
  py: "27px",
  outlineColor: "#D9412A",
  outlineWidth: "2px",
  outlineOffset: "-1px",
  fontWeight: 800,
  zIndex: 0,
});


const accent = defineStyle({
  backgroundColor: "shakumaku.yellow",
  color: "white",
  rounded: "25px",
  py: "27px",
  outlineColor: "shakumaku.darkYellow",
  outlineWidth: "2px",
  outlineOffset: "-1px",
  fontWeight: 800,
  zIndex: 0,
});

const grey = defineStyle({
  backgroundColor: "shakumaku.grey",
  color: "white",
  rounded: "25px",
  py: "27px",
  outlineColor: "#A2A2AD",
  outlineWidth: "2px",
  outlineOffset: "-1px",
  fontWeight: 800,
  zIndex: 0,
});

const buttonTheme = defineStyleConfig({
  variants: { primary, secondary, accent, grey, pink, red },
});

const theme = extendTheme({
  components: { Button: buttonTheme },
  colors: {
    shakumaku: {
      blue: "#53BBE8",
      darkBlue: "#2F98C5",
      yellow: "#F5CD1E",
      darkYellow: "#EFC409",
      green: "#89A960",
      darkGreen: "#729545",
      red: "#E7533D",
      grey: "#A2A2AD",
      pink: "#E3A8CC",
      darkPink: "#D291B9",
    },
  },
  styles: {
    global: () => ({
      body: {
        // backgroundColor: "#53BBE8",
        height: "100%",
        backgroundAttachment: "fixed",
        backgroundColor: "#FFFFFF",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "white",
        backgroundImage: `${backgroundSvg}`,
        fontFamily: "Gilroy",
      },
      input: {
        _disabled: {
          // cursor: "default !important",
          opacity: "0.7 !important",
        },
      },
    }),
  },
});

export default theme;
