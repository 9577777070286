import { Box } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
interface AuthLayoutProps {
  children: ReactNode | ReactNode[];
}

const SignupLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Box mx="5">
      <Box
        display="flex"
        flexDir="column"
        mx="auto"
        p={["5", "5", "5", "10"]}
        maxW="2xl"
        my="5vh"
        bgColor="white"
        color="black"
        borderColor="#CCCCD4"
        borderWidth="2px"
        rounded="35px"
      >
        {children}
      </Box>
    </Box>
  );
};

export default SignupLayout;
