import { Box, Spacer, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage";
import LoadingAnimation from "../../components/LoadingAnimation";
import ProfilePreview from "../../components/ProfilePreview";
import ProgressDropdown from "../../components/ProgressDropdown";
import MainLayout from "../../layouts/MainLayout";
import WarningMessage from "../../layouts/WarningMessage";

export interface ChildAccountProgress {
  completedLessons: string[];
  mainLetters: [{ letterIndex: number; unicode: string; progress: number }];
  totalEasyCompleted: number;
  totalEasyMax: number;
  totalEasyProgress: number;
  totalHard: number;
  totalHardCompleted: number;
  totalHardProgress: number;
  totalMedium: number;
  totalMediumCompleted: number;
  totalMediumProgress: number;
}

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [selectedChildIndex, setSelectedChildIndex] = useState(0);
  const [accountsProgress, setAccountsProgress] = useState<
    ChildAccountProgress[]
  >([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getCurrentAccount = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}accounts/currentAccount`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (res.data.childAccount !== null) {
      await getProgressData(res.data.childAccount.document.profiles);
    }
    return res?.data;
  };

  const getProgressData = async (profiles: { childName: string }[]) => {
    for (const profile of profiles) {
      if (!profile) return;

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}helper-child-info/progress?child_name=${profile.childName}`,
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );

      const accountProgress: ChildAccountProgress = res.data;
      accountsProgress.push(accountProgress);
    }
    return accountsProgress;
  };

  const {
    data: accountData,
    isLoading,
    isError,
  } = useQuery(["currentUser"], getCurrentAccount);

  const updateSelectedChild = (index: number) => {
    setSelectedChildIndex(index);
    onOpen();
  };

  const getSubscriptionStatus = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}helper-subscription/status`,
      {},
      {
        headers: {
          userToken: localStorage.getItem("token"),
        },
      }
    );

    return res?.data;
  };

  const {
    data: subscriptionData,
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
  } = useQuery(["getSubscriptionStatus"], getSubscriptionStatus);

  useEffect(() => {
    isOpen && dropdownRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [dropdownRef.current, isOpen]);

  if ((!accountData || isLoading || isSubscriptionLoading) && !isError)
    return <LoadingAnimation />;

  if (isError) return <ErrorMessage />;

  if (
    !isSubscriptionLoading &&
    subscriptionData.status === "inactive_notowned"
  ) {
    navigate("/settings");
  }

  if (!accountData.childAccount)
    return (
      <WarningMessage positive={true}>
        No Child Profiles to display. <br /> Please head into the app to setup!
      </WarningMessage>
    );

  return (
    <MainLayout>
      <Box
        display="flex"
        flexDir={["column", "column", "column", "row"]}
        mb="20"
        justifyContent="center"
        gap={[20, 0]}
      >
        {accountData?.childAccount?.document.profiles.map(
          (profile: any, i: number) => {
            return (
              <Box key={i} display="flex" mx="20">
                <Spacer />
                <ProfilePreview
                  index={i}
                  selectedChildIndex={selectedChildIndex}
                  isOpen={isOpen}
                  profileData={profile}
                  updateSelectedChild={updateSelectedChild}
                />
                <Spacer />
              </Box>
            );
          }
        )}
      </Box>
      <Box ref={dropdownRef} mx="5">
        {isOpen && (
          <ProgressDropdown
            onClose={onClose}
            childName={
              accountData?.childAccount?.document.profiles[selectedChildIndex]
                .childName
            }
            selectedChildProgress={accountsProgress[selectedChildIndex]}
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default Dashboard;
