import axios from 'axios';
import React, { useEffect } from 'react';

export default async function GetPriceInfo()
{
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}products/sub_metadata`, {
        headers: {
            'Content-Type': 'application/json',
            'application-authorization-token': process.env.REACT_APP_BACKEND_AUTH_TOKEN,
        },
      });
      const data = response.data;
      console.log(data)
      return data
}
