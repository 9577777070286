import { ArrowForwardIcon, CheckIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import AuthLayout from "../../layouts/AuthLayout";
import GetPriceInfo from "../../queries/MetaDataFetcher";

const StartSubscription = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentSuccess = searchParams.get("success");
  const paymentCancelled = searchParams.get("cancelled");
  const [billingPeriod, setBillingPeriod] = useState("year");
  const {
    isLoading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery("priceData", GetPriceInfo);

  const rewardfulCookie = document?.cookie
    ?.match("(^|;)\\s*" + "rewardful.referral" + "\\s*=\\s*([^;]+)")
    ?.pop();

  const createSubscriptionLink = async () => {
    const rewardfulReferral = rewardfulCookie
      ? JSON.parse(decodeURIComponent(rewardfulCookie)).id
      : undefined;

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}payments/subscription-addon`,
      {
        token: localStorage.getItem("token"),
        billingPeriod,
        rewardfulReferral,
        localUrl: "download", // when the user makes a purchase, we redirect them to the download page
        // localUrl: "signup/subscription",
      }
    );

    console.log(res.data);
    window.location.href = res.data.new_location;
    return res?.data;
  };

  const { mutate, isLoading } = useMutation(createSubscriptionLink, {
    onError: (e: AxiosError) => {
      return alert(
        "There has been a critical issue. Please contact support@shakoomaku.com"
      );
    },
  });

  useEffect(() => {
    if (paymentCancelled === "true" || paymentSuccess === "false") {
      alert("Your payment was not completed, please try again.");
    }
  }, []);

  if (paymentSuccess) {
    window.location.href = "/dashboard";
    return <LoadingAnimation />;
  }

  return (
    <AuthLayout>
      <Text
        textAlign="center"
        fontSize="4xl"
        fontFamily="charlie-kingdom"
        textColor="shakumaku.green"
      >
        Subscribe Now!
      </Text>

      <Box mt="4" mx="auto">
        <Text>
          <CheckIcon color="shakumaku.yellow" mr="2" />
          Personalised learning and progress tracking in real-time
        </Text>
        <Text>
          <CheckIcon color="shakumaku.yellow" mr="2" />
          Multiple engaging learning modes
        </Text>
        <Text>
          <CheckIcon color="shakumaku.yellow" mr="2" />
          Built-in rewards system
        </Text>
        <Text>
          <CheckIcon color="shakumaku.yellow" mr="2" />
          Safe and ad-free experience
        </Text>
        <Text>
          <CheckIcon color="shakumaku.yellow" mr="2" />
          New content and updates regularly
        </Text>
      </Box>

      <Box display="flex" flexDir="column" gap="3" color="white" mt="7">
        <Button
          _hover={{ backgroundColor: "" }}
          onClick={() => setBillingPeriod("year")}
          py="12"
          rounded="2xl"
          backgroundColor={
            billingPeriod === "year" ? "shakumaku.darkBlue" : "lightgrey"
          }
          textAlign="left"
        >
          <Text mr="auto">
            {productsLoading ? (
              <>...</>
            ) : (
              <>
                {productsData?.year_display} Annual <br />(
                {productsData?.yearComponent} a month)
              </>
            )}
          </Text>
        </Button>
        <Button
          _hover={{ backgroundColor: "" }}
          onClick={() => setBillingPeriod("month")}
          py="12"
          rounded="2xl"
          backgroundColor={
            billingPeriod === "month" ? "shakumaku.darkBlue" : "lightgrey"
          }
          textAlign="left"
        >
          <Text mr="auto">
            {productsLoading ? (
              <>...</>
            ) : (
              <>
                {productsData?.month_display} Monthly <br />
              </>
            )}
          </Text>
        </Button>
      </Box>

      {isLoading || productsLoading ? (
        <LoadingAnimation />
      ) : (
        <Button
          variant="secondary"
          maxW="xl"
          mx={["10", "20"]}
          mt="8"
          py="6"
          onClick={() => mutate()}
        >
          Subscribe now <ArrowForwardIcon ml="4" />
        </Button>
      )}
    </AuthLayout>
  );
};

export default StartSubscription;
