import { Button, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import AuthLayout from "../../layouts/AuthLayout";

const RequestResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>();

  const submit = async () => {
    if (!email) throw new Error("Please enter your email");

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}accounts/request-password-reset`,
      { email },
      {
        headers: {
          "application-authorization-token":
            process.env.REACT_APP_BACKEND_AUTH_TOKEN,
        },
      }
    );
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(submit, {
    onError: (e: Error | AxiosError) => {
      console.log(JSON.stringify(e, null, 4))
      console.log((e as any))
      console.log((e as any).response.status)
      if ((e as any).response.status !== undefined && (e as any).response.status == 409) {
        return alert(`We could the email address ${email} in our system. Please double check your spelling (including case sensitive characters) try again. If the issue persists, please contact us at hello@shakoomaku.com`);
      }

      return alert(e);
    },
  });

  if (isLoading) return <LoadingAnimation />;

  if (isSuccess && !isError)
    return (
      <AuthLayout>
        <Text fontSize="2xl" textAlign="center">
          A password reset link has been sent to your email!
        </Text>
        <Button
          variant="accent"
          mx="auto"
          w="60"
          mt="10"
          onClick={() => navigate("/login")}
        >
          Login
        </Button>
      </AuthLayout>
    );

  return (
    <form onSubmit={() => mutate()}>
      <AuthLayout>
        <Text
          fontSize="3xl"
          fontWeight="bold"
          mx="auto"
          mb="5"
          mt="5"
          align="center"
          fontFamily="charlie-kingdom"
          textColor="shakumaku.green"
        >
          Request Password Reset
        </Text>

        <Text>
          Please enter your email below, if an account exists with this email a
          password reset link will be emailed to you.
        </Text>

        <FormControl my="5">
          <FormLabel>Email Address</FormLabel>
          <Input
            required
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            rounded="2xl"
          />
        </FormControl>
        <Button
          variant="accent"
          mx="auto"
          w="60"
          mt="3"
          isDisabled={!email}
          type="submit"
        >
          Submit
        </Button>
      </AuthLayout>
    </form>
  );
};

export default RequestResetPassword;
