import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Fade,
  Input,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../../../components/ErrorMessage";
import LoadingAnimation from "../../../components/LoadingAnimation";
import SettingsLayout from "../../../layouts/SettingsLayout";
import WarningMessage from "../../../layouts/WarningMessage";
import { json } from "stream/consumers";
import GetPriceInfo from "../../../queries/MetaDataFetcher";
import { buttonStyle, errorButtonStyle, errorContainerStyle, overlayStyle, textStyle } from "./AccountDeletionStyles";

const AccountDeletion = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [deletionStep, setDeletionStep] = useState<number>(0)

  const [processing, setProcessing] = useState<boolean>(false)
  const [isErrorDeletionFlow, setErrorDeletionFlow] = useState<boolean>(false)

  const [deletionToken01, setDeletionToken01] = useState<string>("")
  const [deletionToken02, setDeletionToken02] = useState<string>("")
  const [code, setCode] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const [errorMessage, setErrorMessage] = useState('An error occurred. Please try again.');

  const [dots, setDots] = useState('.');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots(prevDots => prevDots.length < 5 ? prevDots + '.' : '.');
    }, 500);
    
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getInitialDeletionToken = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}accounts/request-predelete-codetoken`,
        {
          headers: {
            "application-authorization-token": process.env.REACT_APP_BACKEND_AUTH_TOKEN,
            token: localStorage.getItem("token"),
          },
        }
      );
  
      return res?.data.deletionCodeToken;
    } catch (error) {
      console.log('error');  
      return null;
    }
  };
  
  const getDeletionTokenActual = async (deleteToken : string, code : string) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}accounts/request-predelete-actualtoken`,
        {
          code: code
        },
        {
          headers: {
            "application-authorization-token": process.env.REACT_APP_BACKEND_AUTH_TOKEN,
            accountToken: localStorage.getItem("token"),
            deleteCodeToken: deleteToken,
          },
        }
      );
      return res?.data.token
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  const doSignIn = async (email : string, password : string) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}accounts/signin`,
        {
          email: email,
          password : password
        },
        {
          headers: {
            "application-authorization-token": process.env.REACT_APP_BACKEND_AUTH_TOKEN
          },
        }
      );
  
      if (res && res?.data && !res?.data.token) return null;
      return res?.data.token;
    } catch (error) {
      console.log(error);  
      return null;
    }
  };  

  const triggerAccountDeletion = async (deleteToken : string, email : string, password : string) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}accounts/delete-account`,
        {
          accountEmail: email,
          accountPassword: password
        },
        {
          headers: {
            "application-authorization-token": process.env.REACT_APP_BACKEND_AUTH_TOKEN,
            accountToken: localStorage.getItem("token"),
            deleteToken: deleteToken,
          },
        }
      );
  
      return res;
    } catch (error) {
      console.log(error);  
      return null;
    }
  };  

  const getCurrentAccount = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}accounts/currentAccount`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    return res?.data;
  };

  const getSectionDisplay = (index : number) => {
    return (index == deletionStep) ? "block" : "none"
  }

  const deletionFlow = async () => {
    switch(deletionStep) {
      case 0:
        {
          setProcessing(true)
          const response : string | null | undefined = await getInitialDeletionToken()
          setProcessing(false)
          if ( !response ) {
            setErrorDeletionFlow(true)
            setErrorMessage("Could not talk to the server at this time")
            return
          }
          setDeletionToken01(response)
          setDeletionStep(1)
          break;  
        }
      case 1:
        {
          // Code for case 1
          setProcessing(true)
          const response : string | null | undefined = await getDeletionTokenActual(deletionToken01, code)
          setProcessing(false)
          if ( !response ) {
            setErrorDeletionFlow(true)
            setErrorMessage("The code you entered was incorrect")
            return
          }
          setDeletionToken02(response)
          setDeletionStep(2)
          break;
        }
      case 2:
        // Code for case 2
        {
          setProcessing(true)
          const response : string | null | undefined = await doSignIn(email, password)
          setProcessing(false)
          if ( !response ) {
            setErrorDeletionFlow(true)
            setErrorMessage("The login details you provided was incorrect")
            return
          }
          setDeletionStep(3)
        }
        break;
      case 3:
        // Code for case 3
        setDeletionStep(4)
        break;
      case 4:
        {
          setProcessing(true)
          const response = await triggerAccountDeletion(deletionToken02, email, password)
          setProcessing(false)
          if (response && response.status !== 201 || !response)
          {
            setErrorDeletionFlow(true)
            setErrorMessage("There was an error deleting your account")
            return
          }
          setDeletionStep(5)
          // Code for case 4
          break;
        }
        case 5: 
        {
          break
        }
      default:
        // Optional: Code for when none of the cases match
        break;
    }
  }
  
  const {
    data: accountData,
    isLoading,
    isError,
  } = useQuery(["currentUser"], getCurrentAccount);

  if (
    (!accountData || isLoading) &&
    !isError
  )
    return <LoadingAnimation />;

  if (isError) return <ErrorMessage />;

  const handleRefresh = () => {
    window.location.reload();
  };

  const signOut = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <SettingsLayout>
      <Box
        display="flex"
        justifyContent="center"
        flexDir="column"
        gap="14"
        fontSize="3xl"
        mt="5"
        pb="10"
      >
        <Text textAlign="center" fontSize="4xl" fontWeight="bold" mb="5">Account Deletion</Text>

        <Box display={getSectionDisplay(0)}>
          <Text textAlign="center">Before we can continue, you need to enter a 6-digit code to confirm that you are the account holder/parent.</Text>
          <br/>
          <Center>
            <Button fontSize="2xl" padding="8" variant="primary" onClick={() => deletionFlow()}>Send me the code</Button>
          </Center>
        </Box>

        <Box display={getSectionDisplay(1)}>
          <Text textAlign="center">We have sent a 6-digit number to your email. Please enter it and press "Confirm."</Text>
          <br/>
          <Input
            type="text"
            textAlign="center"
            fontSize="5xl"
            padding="10"
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
          <br/>
          <br/>
          <Center>
            <Button fontSize="2xl" padding="8" variant="primary" onClick={() => deletionFlow()}>Confirm</Button>
          </Center>
        </Box>

        <Box display={getSectionDisplay(2)}>
          <Text textAlign="center">Thanks! The 6-digit code was correct. We are almost there. We just need you to log in again.</Text>
          <label>
            Username:
            <Input
              type="text"
              value={email}
              fontSize="3xl"
              padding="10"
              onChange={(event) => setEmail(event.target.value)}
            />
          </label>
          <label>
            Password:
            <Input
              type="password"
              fontSize="3xl"
              padding="10"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </label>
          <br/>
          <br/>
          <Center>
            <Button fontSize="2xl" padding="8" variant="primary" onClick={() => deletionFlow()}>Sign In</Button>
          </Center>
        </Box>

        <Box display={getSectionDisplay(3)} >
          <Center flexDir="column">
            <Text textAlign="center">
              Thank you for confirming that you are the account holder. <br/><br/>
              Please note that this action is irreversible and non-refundable. 
              You will lose your progress. 

              <br/><br/>
              If you are currently subscribed on our platform, you will lose access and will not be refunded for the remaining term of your subscription. 
              If you have subscribed through Apple or Google, you need to cancel the subscription through your platform's app store (App Store, Play Console). 

              <br/><br/>
              Are you sure you want to continue?
            </Text>
            <br/>
            <Button fontSize="2xl" padding="8" variant="primary" onClick={() => deletionFlow()}>Yes</Button>
          </Center>
        </Box>

        <Box display={getSectionDisplay(4)}>
          <Center flexDir="column">
            <Text textAlign="center">
              Again, please note that this action is not reversible. <br/><br/>
              Deleting your account will result in the loss of your progress, and you will not be refunded for any online purchases. <br/><br/>
              For users who made purchases on Apple Devices and/or Android Devices, it is necessary to cancel the account through their respective app store apps. <br/><br/>
              Are you absolutely sure you want to proceed with deleting your account?
            </Text>
            <br/>
            <Button fontSize="2xl" padding="8" variant="red" onClick={() => deletionFlow()}>Yes</Button>
          </Center>
        </Box>

        <Box display={getSectionDisplay(5)}>
          <Center flexDir="column">
            <Text textAlign="center">Your account has been deleted.<br/><br/>
            For information on how to cancel Apple and Google subscriptions, please follow the links below:</Text>
            <br/>
            <Button style={buttonStyle} onClick={() => window.open("https://support.apple.com/HT202039")} variant="secondary">Apple Subscription Management</Button>
            <br/>
            <Button style={buttonStyle} onClick={() => window.open("https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid")} variant="primary">Google Subscription Management</Button>
            <br/>
            <br/>
            <Button style={buttonStyle} onClick={signOut} variant="primary">Sign out</Button>
          </Center>
        </Box>
      </Box>
      {
        processing &&
        <Box style={overlayStyle}>
          <Box style={textStyle}>
            Processing{dots}
          </Box>
        </Box>
      }
      {
        isErrorDeletionFlow && 
        <Box style={overlayStyle}>
          <Box style={errorContainerStyle}>
            <p>{errorMessage}</p>
            <Button style={errorButtonStyle} onClick={handleRefresh}>Refresh</Button>
          </Box>
        </Box>
      }
    </SettingsLayout>
  );
};

export default AccountDeletion;
