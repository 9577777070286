import WarningMessage from "../layouts/WarningMessage";

const ErrorMessage = () => {
  return (
    <WarningMessage positive={false}>
      An unknown error occured <br /> Please try again or contact
      support@shakoomaku.com
    </WarningMessage>
  );
};
export default ErrorMessage;
