import WarningMessage from "../layouts/WarningMessage";

const NotFound = () => {
  return (
    <WarningMessage positive={false}>
      404 Not found - Are you lost?
    </WarningMessage>
  );
};

export default NotFound;
